// Jing

(function (i, s, o, g, r, a, m) {
    i["JingAnalyticsObject"] = r;
    i[r] =
        i[r] ||
        function () {
            (i[r].q = i[r].q || []).push(arguments);
        };
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
})(
    window,
    document,
    "script",
    "https://appcdn.jingsocial.com/js/trak-app-v4.2.1.js",
    "jingTrack"
);

jingTrack({
    appId: "wxb9d5193f65532dff",
    category: "JING-de2680dac",
    openId: "",
    userId: "",
    componentId: "wx4872c0fc3e02785c",
    stopTracking: true,
    stopTrackingPageView: false,
    stopOauthGetOpenId: false,
    unTrackId: [],
});

// 360 analytics
(function (b, a, e, h, f, c, g, s) {
    b[h] =
        b[h] ||
        function () {
            (b[h].c = b[h].c || []).push(arguments);
        };
    b[h].s = !!c;
    g = a.getElementsByTagName(e)[0];
    s = a.createElement(e);
    s.src = "//s.union.360.cn/" + f + ".js";
    s.defer = !0;
    s.async = !0;
    g.parentNode.insertBefore(s, g);
})(window, document, "script", "_qha", 529292, false);

// Baidu analytics
var _hmt = _hmt || [];
(function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?605bad7950cfb7e766711bd95f5c29e9";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();

// New baidu analytics
var _hmt = _hmt || [];
(function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?2507c0011f84c2526291ea9259264e16";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
})();
